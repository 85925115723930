import React, { useState, useEffect } from "react";
import { FileUploader } from "react-drag-drop-files";
import { InboxIcon } from "@heroicons/react/20/solid";
import axios from "axios";
import Tesseract from "tesseract.js";
import { Buffer } from "buffer";
import { io } from "socket.io-client";
import socketClient from "socket.io-client";

import { henadAiType, predict } from "../componets/AiComponent/index";

// CSS
import styles from "./styles.module.css";

const DragAndDrop = ({ 
    patient_images,
    setPatientImages, 
    getAuthToken, 
    setActiveStep 
}) => {
    const file_types = ["JPG", "JPEG", "PNG"];




    const [patient, setPatient] = useState(null);
    const [patients, setPatients] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loading_assign, setLoadingAssign] = useState(false);
    const [loading_progress, setLoadingProgress] = useState(false);
    const [loading_Ai_feth, setLoadingAiFetch] = useState(false);
    const [upload_progress, setUploadProgress] = useState(0); 
    const [total_progress, setTotalProgress] = useState(0);
    const [total_chunks, setTotalChunks] = useState(0);

    useEffect(() => {
        fetchAllPatients();
    }, []);

    const fetchAllPatients = async () => {
        try {
            const token = await getAuthToken();

            const config = {
                method: "get",
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_URL_API}/patients/fetch_all`,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`, // Usa il token ottenuto
                },
                data: { }
            };

            const response = await axios.request(config);

            if (response.data && response.data.status === true) {
                setPatients(response.data.patients);
            } else {
                setPatients([]);
            }
        } catch (error) {
            setPatients([]);
        }
    };

    //setActiveStep(1);
    const generateRandomKey = () => {
        return Math.random().toString(36).substring(2, 10); // Genera 8 caratteri
    };

    const getImageBase64 = (image_file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            // Restituisce solo la parte base64 (senza il prefisso "data:image/*;base64,")
            reader.onload = () => resolve(reader.result.split(",")[1]); 
            reader.onerror = reject;
            reader.readAsDataURL(image_file);
        });
    };
      
    // Funzione per raggruppare le immagini
    const assignCode = async (images) => {
        try {
            let tmp_images = [];

            const token = await getAuthToken();

            const form_data = new FormData();
            for (const image of images) {
                const random_key = generateRandomKey();
    
                // Rinomina l'immagine concatenando la chiave
                const renamed_image = new File(
                    [image],
                    `${random_key}_${image.name}`,
                    { type: image.type }
                );
    
                form_data.append("images", renamed_image);
    
                const image_base64 = await getImageBase64(image);
    
                tmp_images.push({
                    extracted_code: "",
                    original_name: renamed_image.name,
                    random_key: random_key,
                    image_base64
                });
            }

            // Nascondi il loader iniziale
            setLoading(false);
            setLoadingAssign(true);

            const response = await axios.post(
                `${process.env.REACT_APP_URL_API}/patients/assign_code`,
                form_data,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        "Authorization": `Bearer ${token}`
                    }
                }
            );

            if (response.data && response.data.images) {
                const formatted_images = formatImages(
                    tmp_images,
                    response.data.images
                );
                return formatted_images;
            } else {
                setLoadingAssign(false);
                return null;
            }
        } catch (error) {
            setLoadingAssign(false);
            return null;
        }
    };

    const formatImages = (tmp_images, images) => {
        const upd_images = tmp_images.map((tmp_image) => {
            const found_image = images?.find((image) => image.original_name === tmp_image.original_name) || null;

            if(found_image) {
                return {
                    ...tmp_image,
                    extracted_code: found_image?.extracted_code || ""
                };
            }

            return tmp_image;
        });

        return upd_images.filter(image => image.extracted_code);
    }

    const uploadImagesInChunks = async (images, fileImages , code_image_indices) => {    
        try {
            const grouped_images = groupImages(images); // Ottieni un array di gruppi
            const token = await getAuthToken();

            const updated_fileImages = fileImages.filter((_, index) => !code_image_indices.includes(index));

            console.log(images.length, updated_fileImages.length , code_image_indices )

            // Nascondo loading di assegnazione
            setLoadingAssign(false);
    
            for (const group of grouped_images) {
                let url = "";
                let patient = null;
                let new_images_patients = [];
    
                const chunk_size = 2; // Definisci il numero massimo di immagini per chunk
                const total_chunks = Math.ceil(group.length / chunk_size);
                setTotalChunks(group.length);
    
                let completed_chunks = 0;
    
                for (let i = 0; i < group.length; i += chunk_size) {
                    const chunk = group.slice(i, i + chunk_size);
                    const fileImageschunk = updated_fileImages.slice(i, i + chunk_size);

                    
    
                    try {
                        // Impelemtazione Ai per capire il type del immagine
                        const ProvaData =  await henadAiType(fileImageschunk , setLoadingAiFetch);

                        ProvaData.forEach((item)=>{console.log(item.probability)})

                        const updatedChunk = chunk.map((e, index) => {return {...e, type: ProvaData[index].probability  > 0 ? ProvaData[index].className : 'Non definito'}})

                        // Ricavo il paziente
                        const found_patient = patients?.find(patient => patient.code === updatedChunk[0].extracted_code) || null;
                        setPatient(found_patient);
    
                        const data = await uploadChunk(updatedChunk, token);
    
                        if (data) {
                            completed_chunks++;
                            setTotalProgress(prev_tot_progress => prev_tot_progress + updatedChunk.length);
    
                            const percent = Math.round((completed_chunks / total_chunks) * 100);
                            setUploadProgress(percent); // Aggiorna la progress bar

                            if(data.url) {
                                url = data.url;
                                patient = data.patient;
                                new_images_patients.push(...data.new_images_patients);
                            }
                        }
                    } catch (chunkError) {
                        url = "";
                        patient = null;
                        new_images_patients = [];
                    }
                }

                // Attendi un breve momento per mostrare il completamento
                await new Promise(resolve => setTimeout(resolve, 1000));
    
                // Resetta i progressi per il prossimo gruppo
                setTotalProgress(0);
                setUploadProgress(0);

                setPatientImages(prev_patient_images => [
                    ...prev_patient_images,
                    {
                        ...patient,
                        url,
                        src_images: new_images_patients,
                        show: false
                    }
                ]);
            }
            setLoadingProgress(false);

            return true;
        } catch (error) {
            setPatientImages([]);
            setLoadingProgress(false);
            return false;
        }
    };      
    
    const groupImages = (images) => {
        const grouped_images = {};
    
        // Raggruppa le immagini per extracted_code
        images.forEach(image => {
            const code = image.extracted_code;
            if (!grouped_images[code]) {
                grouped_images[code] = [];
            }
            grouped_images[code].push(image);
        });
    
        return Object.values(grouped_images);
    };      

    const uploadChunk = async (
        chunk, 
        token
    ) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_URL_API}/patients/upload_chunk`,
                {
                    chunk
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
    
            if (response.data && response.data.status) {
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return null;
        }
    };
    
    // Funzione per gestire l"upload delle immagini
    const handleChange = async (new_images) => {
        // Mostra il loader iniziale
        setLoading(true);
    
        const images = Array.from(new_images);
        
        const assigned_images = await assignCode(images);
        const code_image_indices = [];
        const filtered_images = assigned_images.filter((item, index) => {
            if (item.extracted_code === "Code_image") {
                code_image_indices.push(index); // Salva l'indice
                return false; // Esclude l'elemento dall'array filtrato
            }
            return true; // Mantiene l'elemento nell'array filtrato
        });
    
        if (filtered_images && filtered_images.length > 0) {
            setLoadingProgress(true);

            // Carica le immagini a chunks
            const status_upload = await uploadImagesInChunks(filtered_images,images , code_image_indices);

            if(status_upload) {
                setActiveStep(1);
            }
        }
    };

    const renderUploader = () => (
        <div className="w-full h-72 flex justify-center items-center border-2 border-dashed border-gray-300 rounded-md">
            <FileUploader
                handleChange={handleChange}
                name="file_uploader"
                types={file_types}
                classes={styles.file_uploader}
                multiple={true}
            >
                <div className="w-full h-full flex flex-col justify-center items-center cursor-pointer">
                    <InboxIcon width={64} height={64} className="text-indigo-500" />
                    <p className="mt-3 font-semibold text-center text-gray-600">
                        Cliccare o trascinare in quest'area per caricare le immagini
                    </p>
                </div>
            </FileUploader>
        </div>
    );
    
    const renderProgressBar = (patient) => (
        <div className="w-full flex flex-col justify-center items-center">
            <p className="text-lg font-semibold text-gray-700 mt-2">
                Caricamento immagini per {patient?.name || "-"} {patient?.surname || "-"}...
            </p>
            <div className="w-full mt-4">
                <div className="w-full bg-gray-200 rounded-full h-4 overflow-hidden">
                    <div
                        className="bg-[#c315a2] h-4 transition-all duration-300 ease-in-out"
                        style={{ width: `${upload_progress}%` }}
                    ></div>
                </div>
                <p className="text-md text-gray-600 text-center mt-2">{upload_progress}%</p>
                <p className="text-md text-gray-600 text-center mt-2">
                <span className="text-indigo-700 font-semibold italic">{total_progress}</span> su {" "}
                <span className="text-indigo-700 font-semibold italic">{total_chunks}</span>
                </p>
            </div>
        </div>
    );
    
    const renderLoading = (message) => (
        <div className="w-full flex flex-col justify-center items-center">
            <div className="w-full flex flex-row justify-center items-center py-4">
                <div className="w-8 h-8 border-4 border-blue-600 border-t-transparent rounded-full animate-spin" />
            </div>
            <p className="text-lg font-semibold text-gray-700 mt-2">{message}</p>
        </div>
    );    

    return (
        <div className="flex-grow mt-4 px-56">
            <header>
                <div className="mx-auto max-w-full px-4 sm:px-6 lg:px-8 bg-white shadow sm:rounded-lg p-6">
                    {/* Stato iniziale: uploader visibile */}
                    {!loading && !loading_progress && !loading_assign && renderUploader()}

                    {/* Stato con paziente associato */}
                    {patient && loading_progress && renderProgressBar(patient)}

                    {/* Stato generico di caricamento */}
                    {!patient && loading && renderLoading("Caricamento immagini...")}

                    {/* Stato di assegnazione pazienti */}
                    {!patient && loading_assign && renderLoading("Assegnazione pazienti...")}

                    {/* Stato di assegnazione Tipologia Tramite AI */}
                    {!patient && loading_Ai_feth && renderLoading("Asseniazione tipologia...")}
                </div>
            </header>
        </div>
    );
}

export default DragAndDrop;