import { useEffect, useState } from "react";

const DynamicRadioGroup = ({ options, name, onChange }) => {


    return (
      <fieldset className="flex justify-center items-center">
        <div className="flex flex-wrap  justify-center items-center space-x-10">
          {options.map((option) => (
            <div key={Math.random()} className="flex items-center">
              <input
                checked={option.cheked}
                id={option.option}
                name={name}
                type="radio"
                value={option.option}
                onChange={onChange}
                className="relative size-4 appearance-none rounded-full border border-gray-300 bg-white before:absolute before:inset-1 before:rounded-full before:bg-white checked:border-indigo-600 checked:bg-indigo-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:border-gray-300 disabled:bg-gray-100 disabled:before:bg-gray-400 forced-colors:appearance-auto forced-colors:before:hidden [&:not(:checked)]:before:hidden"
              />
              <label htmlFor={option.option} className="ml-3 block text-sm/6 font-medium text-gray-900">
                {option.option}
              </label>
            </div>
          ))}
        </div>
      </fieldset>
    );
  };
  
  export default DynamicRadioGroup;
  