import React, { useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import moment from "moment";
import axios from "axios";
import {
    ChevronRightIcon,
    ChevronDownIcon
} from "@heroicons/react/20/solid";

//Importo componenti
import ExpandableTable from "../componets/ExpandableTable";
import ModalImage from "../componets/ModalImage";

const Summary = ({ 
    patient_images,
    setPatientImages, 
    getAuthToken, 
    setActiveStep 
}) => {

    const [data, setData] = useState([]);  
    const [src, setSrc] = useState("");  
    const [loading, setLoading] = useState(false);  
    const [deleteLoading, setdeleteLoading] = useState(false);  
    const [openModalImage, setOpenModalImage] = useState(false);
    const [patient, setPatient] = useState({})

    const columns = [
        { title: "Nome e Cognome", formatter: (item) => <p className="font-semibold">{item.name} {item.surname}</p> },
        { 
            title: "Codice Cliente", 
            formatter: (item) => (
                <span className="bg-blue-100 text-blue-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded">
                    {item.code}
                </span>
            ) 
        },
        { title: "Sesso", formatter: (item) => (item.gender === "female" ? "Donna" : "Uomo") },
        { title: "Luogo di nascita", formatter: (item) => `${item.birthplace} (${String(item.province).toUpperCase()})` },
        { title: "Data di nascita", formatter: (item) => moment(item.birthdate).format("DD/MM/YYYY") },
        { title: "Codice fiscale", formatter: (item) => item.tax_code },
        { title: "Telefono/Cellulare", formatter: (item) => item.phone || "-" },
        { title: "Email", formatter: (item) => item.email || "-"},
        { title: "", formatter: (item) => (
            <button onClick={() => handleShowImages(item)}>
            {
                item && item.show ?
                <ChevronDownIcon className="h-5 w-5" aria-hidden="true" /> :
                <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            }
            </button>
        )},
    ];

    const handleShowImages = (patient) => {

        setPatient(patient)
        setData((prev_data) => {
            const upd_data = prev_data.map(item => {
                if (item._id === patient._id) {
                    return {
                        ...item,
                        show: !item.show
                    };
                }
                return item;
            });
            return upd_data;
        });
    }

    const DeleteAllImages  = async ()=>{
        setdeleteLoading(true);
        try {
            // Ottieni il token in modo asincrono
            const token = await getAuthToken();
            for (const patient of patient_images) {
                const response = await axios.post(
                    `${process.env.REACT_APP_URL_API}/patients/delete-Images`,
                    {
                        patient_id:patient._id,
                        update_fields:patient.src_images
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );  
                
                if (response.patient_images && response.patient_images.status) {
                    console.log(response.patient_images) 
                } else {
                    console.log("Errore durante la chiamata") 
                }

            }
            setActiveStep(0)
            setdeleteLoading(false)
        } catch (error) {
            console.log(error) 
        }
    }

    const renderLoading = (message) => (
        <div className="w-full flex flex-col justify-center items-center">
            <div className="w-full flex flex-row justify-center items-center py-4">
                <div className="w-8 h-8 border-4 border-blue-600 border-t-transparent rounded-full animate-spin" />
            </div>
            <p className="text-lg font-semibold text-gray-700 mt-2">{message}</p>
        </div>
    ); 

    useEffect(() => {
        setData(patient_images);
        setLoading(true);
    }, [patient_images]);


    useEffect(() => {
        const handleBeforeUnload = async (event) => {
            // Blocca il comportamento predefinito del browser
            event.preventDefault();
            event.returnValue = ''; // Questo è necessario per mostrare il prompt in alcuni browser.
    
            console.log('Esecuzione di DeleteAllImages prima del ricaricamento.');
    
            try {
                // Esegui la funzione asincrona e attendi che termini
                await DeleteAllImages();
    
                // Dopo aver completato, forza il ricaricamento manuale
                window.location.reload();
            } catch (error) {
                console.error('Errore durante DeleteAllImages:', error);
            }
        };
    
        window.addEventListener('beforeunload', handleBeforeUnload);
    
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);
    

    return (
        <>
        {!deleteLoading ?
            <div className="flex-grow mt-4">
                <header>
                    <div className="w-full flex flex-row justify-center items-center mb-5">
                        <span className="text-gray-700 text-xl italic">Pazienti individuati:</span>
                        <span className="text-indigo-600 font-semibold text-2xl italic mx-2">{data.length}</span>
                    </div>
                    <div className="mx-20 px-4 sm:px-6 lg:px-8 bg-white shadow sm:rounded-lg p-6">
                        <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                <ExpandableTable
                                    columns={columns}
                                    data={data}
                                    setSrc={setSrc}
                                    setOpenModalImage={setOpenModalImage}
                                    loading={loading}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="w-full flex flex-row justify-center items-center mt-5">
                        <button
                            className="flex items-center px-3 py-2 mr-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                            onClick={() => {
                                setActiveStep(0)
                            }}
                        >
                            Compeleta caricamento
                        </button>
                        <button
                            className="flex items-center px-3 py-2 mr-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            onClick={() => setActiveStep(2)}
                        >
                            Valutazione finale
                        </button>
                    </div>
                </header>
            </div>
        :


        <div className="flex-grow mt-4 px-56">
            <header>
                <div className="mx-auto max-w-full px-4 sm:px-6 lg:px-8 bg-white shadow sm:rounded-lg p-6">
                { renderLoading('Eliminazione Immagini...')}
                </div>
            </header>
        </div>

           
        }


            {openModalImage ? 
                <ModalImage
                    src={src}
                    data={data}
                    setSrc={setSrc}
                    patient={patient}
                    open={openModalImage}
                    setOpen={setOpenModalImage}
                />
            : null}

        </>
    );
}

export default Summary;