import React, { useEffect, useState } from "react";
import {
    XCircleIcon,
    CheckCircleIcon,
    XMarkIcon,
} from "@heroicons/react/20/solid";
import {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
} from "@headlessui/react";
import { useLocation } from "react-router-dom"; // Ottieni la location dal router

import navigazioneData from "./Navigazione"; // Importa l'array originale di navigazione

function MenuNavigazioneMobile({ ruolo, navigate }) {
    const [navigazioneFiltrata, setNavigazioneFiltrata] = useState([]); // Stato per memorizzare l'array filtrato
    const location = useLocation(); // Ottieni la posizione attuale (URL)

    function classNames(...classes) {
        return classes.filter(Boolean).join(" ");
    }

    // Funzione per filtrare l'array in base ai permessi
    function filtraNavigazionePerPermessi(permesso) {
        if (permesso === "admin") {
            return navigazioneData; // Se il ruolo è 'admin', ritorna tutto l'array
        }
        return navigazioneData.filter((item) => item.permessi === permesso); // Filtra l'array in base al permesso fornito
    }

    useEffect(() => {
        // Filtra l'array in base al ruolo
        const navigazioneFiltrata = filtraNavigazionePerPermessi(ruolo).map(
            (item) => {
                // Imposta `current` su true solo se il nome della pagina corrisponde alla route corrente
                return {
                    ...item,
                    current: location.pathname.includes(
                        item.name.toLowerCase(),
                    ),
                };
            },
        );

        // Aggiorna lo stato con l'array filtrato e modificato
        setNavigazioneFiltrata(navigazioneFiltrata);
    }, [ruolo, location.pathname]); // Effettua l'aggiornamento quando il ruolo o il percorso cambiano

    return (
        <div className="space-y-1 pb-3 pt-2">
            {navigazioneFiltrata.map((item) => (
                <DisclosureButton
                    key={item.name}
                    as="a"
                    onClick={() => navigate(`/${item.href}`)}
                    aria-current={item.current ? "page" : undefined}
                    className={classNames(
                        item.current
                            ? "border-indigo-500 bg-indigo-50 text-indigo-700"
                            : "border-transparent text-gray-600 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-800",
                        "block border-l-4 py-2 pl-3 pr-4 text-base font-medium",
                    )}
                >
                    {item.name}
                </DisclosureButton>
            ))}
        </div>
    );
}

export { MenuNavigazioneMobile };
