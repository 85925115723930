import { Fragment } from "react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";

const ExpandableCards = ({ columns, data, loading }) => {

    return (
        <div className="space-y-6">
            {loading ? (
                data && data.length > 0 ? (
                    data.map((item, row_index) => (
                        <div 
                            key={`card_${row_index}`} 
                            className="p-6 bg-white shadow rounded-lg"
                        >
                            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                            {columns.map((column, index) => (
                                <div key={`card_cell_${index}`} className="flex items-center space-x-2">
                                    <h4 className="text-sm font-semibold text-gray-900">
                                        {column.title}
                                    </h4>
                                    <p className="text-sm text-gray-700">
                                        {column.formatter(item, index)}
                                    </p>
                                </div>
                            ))}

                            </div>
                        </div>
                    ))
                ) : (
                    <div className="w-full flex flex-col justify-center items-center py-4">
                        <ExclamationTriangleIcon width={32} height={32} aria-hidden="true" className="text-indigo-600" />
                        <p className="text-sm text-gray-500 text-center whitespace-nowrap">
                            Nessun dato disponibile!
                        </p>
                    </div>
                )
            ) : (
                <div className="w-full flex justify-center items-center px-12 py-4">
                    <div className="w-8 h-8 border-4 border-blue-600 border-t-transparent rounded-full animate-spin" role="status">
                        <span className="sr-only">Caricamento...</span>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ExpandableCards;
