import React, { useRef, useState } from "react";
import * as tf from '@tensorflow/tfjs';

const categories = ['Fronte', 'Sinistra', 'Mandibolare', 'Mascellare', 'Destra'];


export async function henadAiType(chunks , loading) {
    loading(true);
    const model = await tf.loadLayersModel('model0/model.json');
  
    // Usa Promise.all per risolvere tutte le promesse
    const results = await Promise.all(
      chunks.map(async (image) => {
        const dataUrl = await readFileAsDataUrl(image)
        const i = await predict(dataUrl, model);
        return i[0];
      })
    );
    loading(false);
    return results; // Restituisce i risultati risolti
  }

  export async function predict(dataUrl, model) {
      return new Promise((resolve, reject) => {
        try {
          const imageElement = new Image();
          imageElement.src = dataUrl;
    
          imageElement.onload = async function () {
            // Crea un canvas temporaneo
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            
            // Imposta le dimensioni del canvas
            canvas.width = 180;
            canvas.height = 180;
            
            // Disegna l'immagine sull'elemento canvas
            ctx.drawImage(imageElement, 0, 0, canvas.width, canvas.height);
            
            // Estrarre i dati dell'immagine dal canvas come ImageData
            const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
            
            // Converti ImageData in tensor
            const tensor = tf.browser.fromPixels(imageData)
              .toFloat()
              .expandDims(); // Aggiunge la dimensione di batch
    
            try {
              const results = await model.predict(tensor, { batchSize: 1 }).data();
    
              // Processa i risultati per ottenere le categorie
              const order = Array.from(results)
                .map((p, i) => ({
                  probability: p,
                  className: categories[i],
                }))
                .sort((a, b) => b.probability - a.probability)
                .slice(0, 2);
    
              resolve(order);
            } catch (error) {
              reject(error);
            } finally {
              tf.dispose(tensor); // Liberare la memoria occupata dal tensor
            }
          };
    
          imageElement.onerror = (e) => reject(e);
        } catch (error) {
          reject(error);
        }
      });
  }
      
  
  async function readFileAsDataUrl(file) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = function (event) {
        resolve(event.target.result)
      }
      reader.onerror = (e) => reject(e)
      reader.readAsDataURL(file)
    })
  }
  


