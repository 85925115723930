import React, { useState } from "react";
import {
    EyeIcon
} from "@heroicons/react/20/solid";

import ModalNotes from "./ModalNotes";

const Info = ({ fields, default_values }) => {
    const [patient, setPatient] = useState({});
    const [openModalNotes, setOpenModalNotes] = useState(false);

    const grid_variation = {
        1: "lg:col-span-1 col-span-12",
        2: "lg:col-span-2 col-span-12",
        3: "lg:col-span-3 col-span-12",
        4: "lg:col-span-4 col-span-12",
        5: "lg:col-span-5 col-span-12",
        6: "lg:col-span-6 col-span-12",
        7: "lg:col-span-7 col-span-12",
        8: "lg:col-span-8 col-span-12",
        9: "lg:col-span-9 col-span-12",
        10: "lg:col-span-10 col-span-12",
        11: "lg:col-span-11 col-span-12",
        12: "lg:col-span-12 col-span-12"
    };

    // Richiamo funzione che permette la visualizzazione delle note
    const handleViewNotes = (patient) => {
        setPatient(patient);
        setOpenModalNotes(true);
    }

    return (
        <>
            <div className="row w-full grid grid-cols-12 shadow-inner bg-gray-100 border py-5 pl-32 text-left">
            {
                fields.map((item, index) => {
                    return(
                        <div key={index} className={`w-full ${grid_variation[item.colSpan]} mt-2 col-lg-4`}>
                            <div className="mt-1 mx-auto w-full flex flex-row items-center">
                                <span className="text-sm text-gray-900 font-semibold text-center whitespace-nowrap">{item.label}: </span>
                                <span className={`text-sm text-gray-900 text-center whitespace-nowrap ml-2 ${
                                    item.uppercase ? "uppercase" : ""
                                }`}>
                                {
                                    item.name === "notes" ?
                                    <button onClick={() => handleViewNotes(default_values)}>
                                        <EyeIcon className="h-5 w-5 mt-1" aria-hidden="true" />
                                    </button> : (
                                        default_values[item.name] ?
                                        default_values[item.name] :
                                        "-"
                                    )
                                }
                                </span>
                            </div>
                        </div>
                    )
                })
            }
            </div>

            
            <ModalNotes
                patient={patient}
                open={openModalNotes}
                setOpen={setOpenModalNotes}
            />
        </>
    )
}

export default Info;