import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { Fragment } from "react";

// Componenti
import Info from "./Info";

const TablePatients = ({ columns, extra_fields, data, loading }) => {

    return (
        <table  
            className="min-w-full divide-y divide-gray-300"
        >
            <thead className="rounded-t-xl">
                <tr className="w-full">
                    {columns.map((column) => (
                        <th
                            key={column.title}
                            scope="col"
                            className={`px-2 py-3.5 text-left text-sm font-semibold text-gray-900 text-center ${""/*
                                column.title === "Azioni" ? "sticky right-0 bg-gray-100 z-10" : ""
                            */}`}
                        >
                            {column.title}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody className="rounded-b-xl bg-white">
                {loading ? (
                    data && data.length > 0 ? (
                        data.map((item, row_index) => (
                            <Fragment key={`row_${row_index}`}>
                                <tr>
                                    {columns.map((column, index) => (
                                        <td 
                                            key={index}
                                            className={`px-10 py-4 text-sm text-gray-900 text-center whitespace-nowrap ${
                                                row_index !== (data.length - 1) && !item.show ? "border-b": null
                                            }`}
                                        >
                                            {column.formatter(item, index)}
                                        </td>
                                    ))}
                                </tr>
                                {
                                    item.show ?
                                    <tr>
                                        <td
                                            colSpan={columns.length}
                                        >
                                            <Info
                                                fields={extra_fields}
                                                default_values={item}
                                            />
                                        </td>
                                    </tr> : 
                                    null
                                }
                            </Fragment>
                        ))
                    ) : (
                        <tr>
                            <td 
                                colSpan={columns.length}
                                className="px-10 py-4 text-sm text-gray-900 text-center whitespace-nowrap"
                            >
                                <div className="w-full flex flex-col justify-center items-center col-span-4 py-4">
                                    <ExclamationTriangleIcon width={32} height={32} aria-hidden="true" className="text-indigo-600" />
                                    <p className="text-sm text-gray-500 text-center whitespace-nowrap">Nessun dato disponibile!</p>
                                </div>
                            </td>
                        </tr>
                    )
                ) : (
                    <tr>
                        <td colSpan={columns.length}> 
                            <div className="w-full flex justify-center items-center px-12 py-4">
                                <div className="w-8 h-8 border-4 border-blue-600 border-t-transparent rounded-full animate-spin" role="status">
                                    <span className="sr-only">Caricamento...</span>
                                </div>
                            </div>
                        </td>
                    </tr>
                )}
            </tbody>
        </table>

    )
}

export default TablePatients;