import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { login } from "../features/auth/authSlice";
import { useNavigate } from "react-router-dom";
import {
    Label,
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
} from "@headlessui/react";
import {
    CheckIcon,
    ChevronUpDownIcon,
    XCircleIcon,
    EyeIcon,
    EyeSlashIcon,
    ArrowLeftIcon,
} from "@heroicons/react/20/solid";
import axios from "axios";
import Cookies from "js-cookie";
import CryptoJS from "crypto-js";
import { ThreeCircles } from "react-loader-spinner";

//Importo Alert
import { AlertRed, AlertGreen } from "./componets/Alert";

function RecoveryPw() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const lingue = [
        {
            id: 1,
            name: "Italiano",
            avatar: `${process.env.REACT_APP_URL_FILESERVER}/flag/it.png`,
        },
    ];

    // Dichiaro le Variabili
    const [email, setEmail] = useState("");
    const [textRedAlert, setextRedAlert] = useState("");
    const [textGreenAlert, setextGreenAlert] = useState("");
    const [loadingAlertRed, setloadingAlertRed] = useState(false);
    const [loadingAlertGreen, setloadingAlertGreen] = useState(false);
    const [loadingSpinner, setloadingSpinner] = useState(false);
    const [loadingLogin, setloadingLogin] = useState(false);

    // Gestione dei campi del form
    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handleLogin = () => {
        navigate("/login");
    };

    // Funzione per il login
    const handleEmail = async () => {
        if (!email) {
            setextRedAlert(
                "Attenzione! I campi non possono essere vuoti, si prega di riprovare.",
            );
            setloadingAlertRed(true);
            setTimeout(() => {
                setloadingAlertRed(false);
                setextRedAlert("");
            }, 2000);
        } else {
            try {
                let config = {
                    method: "post",
                    maxBodyLength: Infinity,
                    url: `${process.env.REACT_APP_URL_API}/users/forgot_password`,
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                    data: {
                        username: email,
                        language: "it-IT",
                    },
                };

                const response = await axios.request(config);

                if (response.data.status === true) {
                    setextGreenAlert(
                        "Password resettata con successo. A breve riceverete un email con la nuova password temporanea.",
                    );
                    setloadingAlertGreen(true);

                    setTimeout(() => {
                        setloadingAlertGreen(false);
                        setextGreenAlert("");
                    }, 5000);

                    setEmail("");

                    setloadingLogin(true);
                } else {
                    setextRedAlert("Attenzione! Utente non trovato.");
                    setloadingAlertRed(true);
                    setTimeout(() => {
                        setloadingAlertRed(false);
                        setextRedAlert("");
                    }, 4000);
                }

                return response.data;
            } catch (error) {
                console.error("Error durante il reset:", error);
                throw error;
            }
        }
    };

    return (
        <>
            <div className="flex min-h-screen flex-1">
                <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
                    <div className="mx-auto w-full max-w-sm lg:w-96">
                        <div>
                            <img
                                alt="Logo Centro del Sorriso di Cuneo"
                                src={`${process.env.REACT_APP_URL_FILESERVER}/logo_top.png`}
                                className="h-20 w-auto"
                            />
                            <h2 className="mt-2 text-2xl font-bold leading-9 tracking-tight text-gray-900">
                                Recupera Credenziali
                            </h2>
                        </div>

                        {loadingAlertRed ? (
                            <AlertRed testo={textRedAlert} />
                        ) : null}
                        {loadingAlertGreen ? (
                            <AlertGreen testo={textGreenAlert} />
                        ) : null}

                        <div className="mt-6">
                            <div>
                                <div className="space-y-4">
                                    {!loadingLogin ? (
                                        <div>
                                            <label
                                                htmlFor="email"
                                                className="block text-sm font-medium leading-6 text-gray-900"
                                            >
                                                Nome Utente
                                            </label>
                                            <div className="mt-1">
                                                <input
                                                    onChange={handleEmailChange}
                                                    value={email}
                                                    id="email"
                                                    name="email"
                                                    type="email"
                                                    autoComplete="email"
                                                    className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                />
                                            </div>
                                        </div>
                                    ) : null}

                                    {!loadingLogin ? (
                                        <div className="flex items-center justify-start">
                                            <div className="flex items-center">
                                                <ArrowLeftIcon
                                                    className="h-3 w-3 text-indigo-600"
                                                    aria-hidden="true"
                                                />
                                            </div>

                                            <div className="text-sm leading-6">
                                                <button
                                                    onClick={handleLogin}
                                                    className="font-semibold text-indigo-600 hover:text-indigo-500"
                                                >
                                                    Ritorna al login
                                                </button>
                                            </div>
                                        </div>
                                    ) : null}

                                    <div>
                                        {!loadingLogin ? (
                                            <button
                                                onClick={handleEmail}
                                                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                            >
                                                {loadingSpinner ? (
                                                    <ThreeCircles
                                                        visible={true}
                                                        height="20"
                                                        width="20"
                                                        color="#FFFFFF"
                                                    />
                                                ) : null}
                                                Recupera
                                            </button>
                                        ) : null}

                                        {loadingLogin ? (
                                            <button
                                                onClick={handleLogin}
                                                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                            >
                                                Ritorna al login
                                            </button>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="relative hidden w-0 flex-1 lg:block">
                    <img
                        className="absolute inset-0 h-full w-full object-cover"
                        src={`${process.env.REACT_APP_URL_FILESERVER}/logo.png`}
                        alt=""
                    />
                </div>
            </div>
        </>
    );
}

export default RecoveryPw;
