import React, { useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import moment from "moment";
import axios from "axios";
import {
    ChevronRightIcon,
    ChevronDownIcon
} from "@heroicons/react/20/solid";

//Importo componenti
import PesiantCard from "../componets/PesiantCard";
import ModalImage from "../componets/ModalImagesApruved";
//Importo Autenticazione Token
import getAuthToken from "../../features/auth/axiosSetup";

const Summary = ({ 
    patient_images,
    setPatientImages, 
    getAuthToken, 
    setActiveStep 
}) => {

    const [totApprovati, setTotApprovati] = useState(0);
    const [totNonApprovati, setTotNonApprovati] = useState(0);

    const [src, setSrc] = useState("");  
    const [loading, setLoading] = useState(false);  
    const [finalloading, setFinalLoading] = useState(false);  
    const [openModalImage, setOpenModalImage] = useState(false);
    const [checkboxState, setCheckboxState] = useState("toBeEvaluated");
    const [imageType, setImageType] = useState('');


    
    const [patient, setPatient] = useState({})

    const columns = [

        { title: "Nome e Cognome:", formatter: (item) => <p className="font-semibold">{item.name} {item.surname}</p> },
        { 
            title: "Codice Cliente:", 
            formatter: (item) => (
                <span className="bg-blue-100 text-blue-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded">
                    {item.code}
                </span>
            ) 
        },
        { title: "", formatter: (item) => (

            <button
                type="button"
                className="rounded-md bg-[#c315a233] px-2.5 py-1.5 text-sm font-semibold text-[#c315a2] shadow-sm hover:bg-[#c315a260]"
                onClick={() => { 

                let totApprovati = 0
                let totNonApprovati = 0

                item.src_images.forEach(e => {
                    if(e.stato === "approve"){
                        totApprovati++
                    }else if(e.stato === "notApprove"){
                        totNonApprovati++
                    }
                });

                setTotApprovati(totApprovati);
                setTotNonApprovati(totNonApprovati);

                setCheckboxState(item.src_images[0].stato)
                setImageType(item.src_images[0].type)
                setSrc(`${item.url}/${item.src_images[0].path}`);
                setPatient(item)
                setOpenModalImage(true)}}
            >
                Valuta immagini
            </button>
        )},
    ];

    const UpdateImageSate = async ()=>{
        setFinalLoading(true);
        try {
            // Ottieni il token in modo asincrono
            const token = await getAuthToken();
            for (const patient of patient_images) {
                const response = await axios.post(
                    `${process.env.REACT_APP_URL_API}/patients/update-imgState`,
                    {
                        patient_id:patient._id,
                        update_fields:patient.src_images
                    },
                    {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );  
                
                if (response.patient_images && response.patient_images.status) {
                    console.log(response.patient_images) 
                } else {
                    console.log("Errore durante la chiamata") 
                }
            }
            setActiveStep(0)
            setFinalLoading(false);
        } catch (error) {
            console.log(error) 
        }
    }

    const handleShowImages = (patient) => {
        setPatientImages((prev_patient_images) => {
            const upd_patient_images = prev_patient_images.map(item => {
                if (item._id === patient._id) {
                    return {
                        ...item,
                        show: !item.show
                    };
                }
                return item;
            });
            return upd_patient_images;
        });
    }

    const renderLoading = (message) => (
        <div className="w-full flex flex-col justify-center items-center">
            <div className="w-full flex flex-row justify-center items-center py-4">
                <div className="w-8 h-8 border-4 border-blue-600 border-t-transparent rounded-full animate-spin" />
            </div>
            <p className="text-lg font-semibold text-gray-700 mt-2">{message}</p>
        </div>
    ); 

    useEffect(() => {
        setLoading(true);
    }, [patient_images]);

    return (
        <>

        {!finalloading 
            ?
                <div className="flex-grow mt-4">
                    <header>
                        <div className="w-full flex flex-row justify-center items-center mb-5">
                            <span className="text-gray-700 text-xl italic">Pazienti individuati:</span>
                            <span className="text-indigo-600 font-semibold text-2xl italic mx-2">{patient_images.length}</span>
                        </div>
                        <div className="mx-20 px-4 sm:px-6 lg:px-8 bg-white shadow sm:rounded-lg p-6">
                            <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
                                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                    <PesiantCard
                                        columns={columns}
                                        data={patient_images}
                                        loading={loading}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="w-full flex flex-row justify-center items-center mt-5">
                            <button
                                className="flex items-center px-3 py-2 mr-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                onClick={() => {
                                    setActiveStep(1);
                                }}
                            >
                                Torna indietro
                            </button>
                            <button
                                className="flex items-center px-3 py-2 mr-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                onClick={() => UpdateImageSate()}
                            >
                                Avanti
                            </button>
                        </div>
                    </header>
                </div>
            :
                <div className="flex-grow mt-4 px-56">
                    <header>
                        <div className="mx-auto max-w-full px-4 sm:px-6 lg:px-8 bg-white shadow sm:rounded-lg p-6">
                        { renderLoading('Elaborazione finale...')}
                        </div>
                    </header>
                </div>
        }


            {openModalImage ? 
                <ModalImage
                    src={src}
                    data={patient_images}
                    setData={setPatientImages}
                    checkboxState={checkboxState}
                    setCheckboxState={setCheckboxState}
                    imageType={imageType}
                    approvati={totApprovati}
                    nonApprovati={totNonApprovati}
                    setImageType={setImageType}
                    patient={patient}
                    setSrc={setSrc}
                    open={openModalImage}
                    setOpen={setOpenModalImage}
                />
            : null}

        </>
    );
}

export default Summary;