import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { logout } from "../features/auth/authSlice";
import { useNavigate } from "react-router-dom";
import {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
} from "@headlessui/react";
import {
    Dialog,
    DialogBackdrop,
    DialogPanel,
    DialogTitle,
} from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
import {
    PlusCircleIcon,
    PencilSquareIcon,
    TrashIcon,
    EyeIcon,
    EyeSlashIcon,
} from "@heroicons/react/20/solid";
import Cookies from "js-cookie";
import CryptoJS from "crypto-js";

//Importo Menu
import { MenuDesktop } from "./componets/Menu_desktop";
import { MenuMobile } from "./componets/Menu_mobile";
import { MenuNavigazioneMobile } from "./componets/Menu_navigazioneMobile";
import { MenuNavigazioneDesktop } from "./componets/Menu_navigazioneDesktop";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";

//Importo Autenticazione Token
import getAuthToken from "../features/auth/axiosSetup";
//Importo Alert
import { AlertRed, AlertGreen } from "./componets/Alert";
import axios from "axios";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

function Utenti() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    //Funzione per recuperare tutta la lista degli utenti
    const getAllConnection = async () => {
        try {
            // Ottieni il token in modo asincrono
            const token = await getAuthToken();

            // Configura la richiesta
            let config = {
                method: "get",
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_URL_API}/users/fetch_all`,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    Authorization: `Bearer ${token}`, // Usa il token ottenuto
                },
                data: new URLSearchParams({
                    usernameGet: getDecryptedCookie(
                        process.env.REACT_APP_COOKIE_USERNAME,
                    ),
                }),
            };

            // Effettua la richiesta con axios
            const response = await axios.request(config);

            // Controllo la risposta
            if (response.data.status === true) {
                // Filtra gli utenti per escludere quelli con ruolo 'system'
                const filteredUsers = response.data.users.filter(
                    (user) => user.role !== "system",
                );

                // Setta lo state con gli utenti filtrati
                setUsers(filteredUsers);

                // Set loading
                setloadingUsers(true);
            } else {
                // Gestisci il caso in cui la risposta abbia status false
                console.log("Errore nella risposta:", response.data.message);
                // Potresti anche gestire il caso di errore impostando un messaggio di stato o un alert
            }
        } catch (error) {
            console.log("Errore nella richiesta:", error);
            // Gestione dell'errore, ad esempio aggiornando lo stato per mostrare un messaggio all'utente
        }
    };

    useEffect(() => {
        getAllConnection();
    }, []);

    //Dichiaro gli State
    const [nome, setNome] = useState("");
    const [cognome, setCognome] = useState("");
    const [ruolo, setRuolo] = useState("");
    const [email, setEmail] = useState("");
    const [deleteUsername, setdeleteUsername] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5); // Puoi cambiare il numero per pagina qui
    const [Users, setUsers] = useState([{}]); 
    const [loadingUsers, setloadingUsers] = useState(false);
    const [open, setOpen] = useState(false);
    const [openAdd, setOpenAdd] = useState(false);
    const [textRedAlert, setextRedAlert] = useState("");
    const [textGreenAlert, setextGreenAlert] = useState("");
    const [loadingAlertRed, setloadingAlertRed] = useState(false);
    const [loadingAlertGreen, setloadingAlertGreen] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [isValid, setIsValid] = useState(true);
    const [edit, setEdit] = useState(false);

    //State per Aggiunta Utente
    const [addNome, setaddNome] = useState("");
    const [addpassword, setaddpassword] = useState("");
    const [addCognome, setaddCognome] = useState("");
    const [addRuolo, setaddRuolo] = useState("");
    const [addUsername, setaddUsername] = useState("");
    const [addEmail, setaddEmail] = useState("");

    //State per passaggio dati modale modifica utente
    const [userData, setUserData] = useState({
        name: "",
        surname: "",
        email: "",
        username: "",
        password: "",
        role: ""
    });

    // State per gestione dati dell'utente selezionato
    const [selectedUser, setSelectedUser] = useState(null);

    // Funzione per gestire la selezione dell'utente
    const handleSelectUser = (userId) => {
        const user = Users.find((user) => user._id === userId);
        setSelectedUser(user); // Aggiorna lo stato con i dati dell'utente selezionato
    
        // Popola i campi del form con i dati dell'utente selezionato
        if (user) {
            setUserData({
                name: user.name,
                surname: user.surname,
                email: user.email,
                username: user.username,
                password: "", 
                role: user.role
            });
        }
    };

    //Gestione cambio dati form
    const handleChange = (event) => {
        const { name, surname, email, password, value } = event.target;
        setUserData((prevData) => ({
            ...prevData,
            [name]: value,
            [surname]: value,
            [email]: value,
            [password]: value
        }));
    };
    
    //Gestione regex password (min.8 caratteri, almeno un carattere speciale, una lettera maiscola, una minuscola e un numero)
    const validatePassword = (value) => {
        // Verifica che la password non sia vuota prima di fare la validazione
        if (value.length === 0) {
            setIsValid(true);
            return; 
        }
        
        const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&-])[A-Za-z\d@$!%*?&-]{8,}$/;
        setIsValid(pattern.test(value));
    };

    //Funzione per gestire il controllo password
    const handleControlPassword = (event) => {
        validatePassword(event.target.value);
    }

    //Handle set

    const handleaddNome = (event) => {
        setaddNome(event.target.value);
    };

    const handleaddpassword = (event) => {
        setaddpassword(event.target.value);
    };

    const handleaddCognome = (event) => {
        setaddCognome(event.target.value);
    };

    const handleaddRuolo = (event) => {
        setaddRuolo(event.target.value);
    };

    const handleaddUsername = (event) => {
        setaddUsername(event.target.value);
    };

    const handleaddEmail = (event) => {
        setaddEmail(event.target.value);
    };

    // Logica per dividere `people` in pagine
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = Users.slice(indexOfFirstItem, indexOfLastItem);

    // Calcolo del numero totale di pagine
    const totalPage = Math.ceil(Users.length / itemsPerPage);

    // Cambia la pagina
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // Esempio di navigazione pagina
    const nextPage = () =>
        setCurrentPage((prev) => (prev < totalPage ? prev + 1 : prev));
    const prevPage = () =>
        setCurrentPage((prev) => (prev > 1 ? prev - 1 : prev));

    // Funzione per cancellare i Cookie criptati
    function removeEncryptedCookie(key) {
        // Rimuove il cookie specificato
        Cookies.remove(key);
    }
    // Funzione per riprendere i cookie salvati
    function getDecryptedCookie(key) {
        try {
            const encryptedValue = Cookies.get(key);

            if (!encryptedValue) {
                return null;
            }
            // Decriptazione
            const bytes = CryptoJS.AES.decrypt(
                encryptedValue,
                process.env.REACT_APP_COOKIE_SECRET,
            );

            if (bytes) {
                const decryptedValue = bytes.toString(CryptoJS.enc.Utf8);

                if (decryptedValue) {
                    return decryptedValue;
                } else {
                    return null;
                }
            } else {
                return null;
            }
        } catch (error) {
            return null;
        }
    }

    //Funzione per cancellare utente
    const deleteUser = async (utente) => {
        try {
            // Ottieni il token in modo asincrono
            const token = await getAuthToken();

            // Configura la richiesta
            let config = {
                method: "delete",
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_URL_API}/users/delete_user`,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    Authorization: `Bearer ${token}`, // Usa il token ottenuto
                },
                data: new URLSearchParams({
                    username: utente,
                }),
            };

            // Effettua la richiesta con axios
            const response = await axios.request(config);

            //Controllo la risposta e di conseguenza pubblico la risposta con allert
            // Controllo la risposta
            if (response.data.status === true) {
                setextGreenAlert("Utente cancellato con successo!");
                setloadingAlertGreen(true);

                setTimeout(() => {
                    setloadingAlertGreen(false);
                    setextGreenAlert("");
                }, 3000);

                //Svuoto lo State dell'utente da cancellare
                setdeleteUsername("");

                //Chiudo il Modale
                setOpen(false);

                //Ricarico la tabella utenti

                getAllConnection();
            } else {
                setextRedAlert(
                    "Attenzione! Si è verificato un errore nella cancellazione dell'utente.",
                );
                setloadingAlertRed(true);
                setTimeout(() => {
                    setloadingAlertRed(false);
                    setextRedAlert("");
                }, 2000);
            }
        } catch (error) {
            console.log("Errore nella richiesta:", error);
            // Gestione dell'errore, ad esempio aggiornando lo stato per mostrare un messaggio all'utente
        }
    };

    //Fine Cancellazione Utente

    // Recupero le informazioni dai Cookie
    // Esegui il controllo una sola volta quando la pagina viene montata
    useEffect(() => {
        setNome(getDecryptedCookie(process.env.REACT_APP_COOKIE_FNAME));
        setCognome(getDecryptedCookie(process.env.REACT_APP_COOKIE_LNAME));
        setRuolo(getDecryptedCookie(process.env.REACT_APP_COOKIE_ROLE));
        setEmail(getDecryptedCookie(process.env.REACT_APP_COOKIE_EMAIL));
    }, []);

    const handleLogout = () => {
        //Cancello i Cookiee
        removeEncryptedCookie(process.env.REACT_APP_COOKIE_FNAME);
        removeEncryptedCookie(process.env.REACT_APP_COOKIE_LNAME);
        removeEncryptedCookie(process.env.REACT_APP_COOKIE_ROLE);
        removeEncryptedCookie(process.env.REACT_APP_COOKIE_EMAIL);
        removeEncryptedCookie(process.env.REACT_APP_COOKIE_USERNAME);

        //Eseguo Logout
        dispatch(logout());
        navigate("/login");
    };

    const handleProfilo = () => {
        navigate("/profilo");
    };

    //Funzione per Generare l'immagine
    function generateInitialsImage(name, surname) {
        const initials = (name[0] || "") + (surname[0] || "");
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");

        // Imposta le dimensioni del canvas
        const size = 80; // Dimensione dell'immagine
        canvas.width = size;
        canvas.height = size;

        // Imposta il colore di sfondo
        context.fillStyle = "#5930B6"; // Colore di sfondo (puoi personalizzarlo)
        context.fillRect(0, 0, size, size);

        // Imposta le proprietà del testo
        context.fillStyle = "#fff"; // Colore del testo
        context.font = "36px Urbanist, sans-serif"; // Font e dimensione del testo

        context.textAlign = "center";
        context.textBaseline = "middle";

        // Disegna le iniziali al centro del canvas
        context.fillText(initials, size / 2, size / 2);

        // Ritorna l'URL dell'immagine generata
        return canvas.toDataURL("image/png");
    }

    // Funzione per Generare l'immagine
    function generateInitialsImageContrast(name, surname) {
        const initials = (name[0] || "") + (surname[0] || "");
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");

        // Imposta le dimensioni del canvas
        const size = 80; // Dimensione dell'immagine
        canvas.width = size;
        canvas.height = size;

        // Funzione per generare un colore casuale
        function getRandomColor() {
            const letters = "0123456789ABCDEF";
            let color = "#";
            for (let i = 0; i < 6; i++) {
                color += letters[Math.floor(Math.random() * 16)];
            }
            return color;
        }

        // Calcola il contrasto del colore per il testo
        function getTextColor(bgColor) {
            const color = bgColor.slice(1); // togliere il '#' per il calcolo
            const rgb = parseInt(color, 16); // converti in valore rgb decimale
            const red = (rgb >> 16) & 0xff; // estrai il componente rosso
            const green = (rgb >> 8) & 0xff; // estrai il componente verde
            const blue = (rgb >> 0) & 0xff; // estrai il componente blu
            const brightness = 0.2126 * red + 0.7152 * green + 0.0722 * blue; // calcolo luminosità
            return brightness < 128 ? "#FFFFFF" : "#000000"; // luminosità bassa = testo bianco, altrimenti nero
        }

        // Imposta il colore di sfondo casualmente
        const backgroundColor = getRandomColor();
        context.fillStyle = backgroundColor; // Colore di sfondo
        context.fillRect(0, 0, size, size);

        // Imposta le proprietà del testo basate sul contrasto
        context.fillStyle = getTextColor(backgroundColor); // Colore del testo a contrasto
        context.font = "36px Urbanist, sans-serif"; // Font e dimensione del testo

        context.textAlign = "center";
        context.textBaseline = "middle";

        // Disegna le iniziali al centro del canvas
        context.fillText(initials, size / 2, size / 2);

        // Ritorna l'URL dell'immagine generata
        return canvas.toDataURL("image/png");
    }

    // Genera l'immagine con le iniziali dell'utente
    const initialsImageUrl = generateInitialsImage(nome, cognome);

    const GetAnno = () => {
        return new Date().getFullYear();
    };

    //Richiamo funzione per canccellare utente una volta che utente a dato ok
    const handleDelete = (utente) => {
        setdeleteUsername(utente);
        setOpen(true);
    };

    //Funzione per Creare un Nuovo Utente
    const handleAddUser = async () => {
        try {
            // Ottieni il token in modo asincrono
            const token = await getAuthToken();

            // Configura la richiesta
            let config = {
                method: "post",
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_URL_API}/users/newUsers`,
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    Authorization: `Bearer ${token}`, // Usa il token ottenuto
                },
                data: new URLSearchParams({
                    name: addNome,
                    surname: addCognome,
                    email: addEmail,
                    username: addUsername,
                    password: addpassword,
                    role: addRuolo,
                }),
            };

            // Effettua la richiesta con axios
            const response = await axios.request(config);

            //Controllo la risposta e di conseguenza pubblico la risposta con alert
            //Controllo la risposta
            if (response.data.status === true) {
                setextGreenAlert("Utente creato con successo!");
                setloadingAlertGreen(true);

                setTimeout(() => {
                    setloadingAlertGreen(false);
                    setextGreenAlert("");
                }, 3000);

                //Svuoto lo State
                setaddNome("");
                setaddCognome("");
                setaddUsername("");
                setaddEmail("");
                setaddRuolo("");
                setaddpassword("");

                //Chiudo il Modale
                setOpenAdd(false);

                //Ricarico la tabella utenti
                getAllConnection();
            } else {
                setextRedAlert("Attenzione! " + response.data.message);
                setloadingAlertRed(true);
                setTimeout(() => {
                    setloadingAlertRed(false);
                    setextRedAlert("");
                }, 2000);
            }
        } catch (error) {
            console.log("Errore nella richiesta:", error);
            // Gestione dell'errore, ad esempio aggiornando lo stato per mostrare un messaggio all'utente
        }
    };
    
    //Funzione per modificare i dati dell'utente
    const handleUpdateUser = async () => {
        try {
            const token = await getAuthToken();
    
            const updateFields = { ...userData };
    
            const config = {
                method: "put",
                maxBodyLength: Infinity,
                url: `${process.env.REACT_APP_URL_API}/users/update_user`,
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`
                },
                data: {
                    userId: selectedUser._id,
                    updateFields: updateFields
                }
            };
    
            const response = await axios.request(config);
    
            if (response.data.status === true) {
                setextGreenAlert("Utente aggiornato con successo!");
                setloadingAlertGreen(true);
                setTimeout(() => {
                    setloadingAlertGreen(false);
                    setextGreenAlert("");
                }, 3000);
    
                // Chiudo il modale
                setOpenAdd(false);
    
                // Ricarico la tabella degli utenti
                getAllConnection();
            } else {
                setextRedAlert("Attenzione! " + response.data.message);
                setloadingAlertRed(true);
                setTimeout(() => {
                    setloadingAlertRed(false);
                    setextRedAlert("");
                }, 2000);
            }
        } catch (error) {
            console.log("Errore nella richiesta:", error);
        }
    };
    

    return (
        <>
            <Tooltip id="edit-bt" />
            <Tooltip id="delete-bt" />
            <div className="flex flex-col min-h-screen bg-gray-100">
                <Disclosure as="nav" className="bg-white shadow-sm">
                    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                        <div className="flex h-16 justify-between">
                            <div className="flex">
                                <div className="flex flex-shrink-0 items-center">
                                    <img
                                        alt="Your Company"
                                        src="https://fileserver.centrodelsorriso.tribusinformatica.it/logo_top.png"
                                        className="block h-8 w-auto lg:hidden"
                                    />
                                    <img
                                        alt="Your Company"
                                        src="https://fileserver.centrodelsorriso.tribusinformatica.it/logo_top.png"
                                        className="hidden h-8 w-auto lg:block"
                                    />
                                </div>
                                <MenuNavigazioneDesktop
                                    ruolo={ruolo}
                                    navigate={navigate}
                                />
                            </div>

                            <div className="hidden sm:ml-6 sm:flex sm:items-center">
                                {/* Profile dropdown */}
                                <MenuDesktop
                                    nome={nome}
                                    cognome={cognome}
                                    ruolo={ruolo}
                                    email={email}
                                    initialsImageUrl={initialsImageUrl}
                                    handleLogout={handleLogout}
                                    handleProfilo={handleProfilo}
                                />
                            </div>

                            {/* Mobile Section */}
                            <div className="-mr-2 flex items-center sm:hidden">
                                {/* Mobile menu button */}
                                <DisclosureButton className="group relative inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                    <span className="absolute -inset-0.5" />
                                    <span className="sr-only">
                                        Open main menu
                                    </span>
                                    <Bars3Icon
                                        aria-hidden="true"
                                        className="block h-6 w-6 group-data-[open]:hidden"
                                    />
                                    <XMarkIcon
                                        aria-hidden="true"
                                        className="hidden h-6 w-6 group-data-[open]:block"
                                    />
                                </DisclosureButton>
                            </div>
                        </div>
                    </div>

                    {/* Mobile  */}
                    <DisclosurePanel className="sm:hidden">
                        <MenuNavigazioneMobile
                            ruolo={ruolo}
                            navigate={navigate}
                        />
                        <MenuMobile
                            nome={nome}
                            cognome={cognome}
                            ruolo={ruolo}
                            email={email}
                            initialsImageUrl={initialsImageUrl}
                            handleLogout={handleLogout}
                            handleProfilo={handleProfilo}
                        />
                    </DisclosurePanel>
                </Disclosure>

                <div className="flex-grow py-10 ">
                    <header>
                        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 bg-white shadow sm:rounded-lg p-8">
                            <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">
                                Gestione Utenti
                            </h1>
                            <h3 className="text-base font-semibold leading-7 text-gray-900">
                                Informazioni
                            </h3>
                            <p className="mt-1 max-w-2xl text-sm leading-6 text-gray-500">
                                Sezione dedicata alla gestione degli utenti.
                            </p>

                            <div className="mt-2">
                                <div>
                                    <div className="px-4 sm:px-0 "></div>
                                    
                                    <div className="w-full">
                                        {loadingAlertGreen ? (
                                            <AlertGreen
                                                testo={textGreenAlert}
                                            />
                                        ) : null}
                                    </div>

                                    {/***/}
                                    <div className="mt-8 flow-root">
                                        <div className="flex justify-between items-center pb-4">
                                            <h2 className="text-lg leading-6 font-medium text-gray-900">
                                                Gestione Utenti
                                            </h2>
                                            <button
                                                className="flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                                onClick={() => setOpenAdd(true)}
                                            >
                                                <PlusCircleIcon
                                                    className="h-5 w-5 mr-2"
                                                    aria-hidden="true"
                                                />
                                                Aggiungi
                                            </button>
                                        </div>
                                        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                                <table className="min-w-full divide-y divide-gray-300">
                                                    <thead>
                                                        <tr>
                                                            <th
                                                                scope="col"
                                                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                                                            >
                                                                Nome e Cognome
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                                            >
                                                                Nome Utente
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                                            >
                                                                Stato
                                                            </th>
                                                            <th
                                                                scope="col"
                                                                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                                            >
                                                                Ruolo
                                                            </th>

                                                            <th
                                                                scope="col"
                                                                className="relative py-3.5 pl-3 pr-4 sm:pr-0"
                                                            >
                                                                <span className="sr-only">
                                                                    Modifica
                                                                </span>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    {loadingUsers ? (
                                                        <tbody className="divide-y divide-gray-200 bg-white">
                                                            {Users.map(
                                                                (person) => (
                                                                    <tr
                                                                        key={
                                                                            person.email
                                                                        }
                                                                    >
                                                                        <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0">
                                                                            <div className="flex items-center">
                                                                                <div className="h-11 w-11 flex-shrink-0">
                                                                                    <img
                                                                                        alt=""
                                                                                        src={generateInitialsImageContrast(
                                                                                            person.name,
                                                                                            person.surname,
                                                                                        )}
                                                                                        className="h-11 w-11 rounded-full"
                                                                                    />
                                                                                </div>
                                                                                <div className="ml-4">
                                                                                    <div className="font-medium text-gray-900">
                                                                                        {
                                                                                            person.name
                                                                                        }{" "}
                                                                                        {
                                                                                            person.surname
                                                                                        }
                                                                                    </div>
                                                                                    <div className="text-gray-500">
                                                                                        {
                                                                                            person.email
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                                                                            {
                                                                                person.username
                                                                            }
                                                                        </td>
                                                                        <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                                                                            <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                                                                                Active
                                                                            </span>
                                                                        </td>
                                                                        <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                                                                            {
                                                                                person.role === 'admin' ? 'Admin' : 'User'
                                                                            }
                                                                        </td>
                                                                        <td className="whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0">
                                                                            <div className="flex justify-center">
                                                                                <div className="items-center">
                                                                                    <button
                                                                                        data-tooltip-id="edit-bt"
                                                                                        data-tooltip-content="Modifica"
                                                                                        data-tooltip-place="top"
                                                                                        onClick={() => {
                                                                                            setOpenAdd(true);
                                                                                            setEdit(true);
                                                                                            handleSelectUser(person._id);
                                                                                        }}                                                                                        
                                                                                        className="text-yellow-500 hover:text-yellow-600 flex items-center"
                                                                                    >
                                                                                        <PencilSquareIcon className="h-5 w-5" aria-hidden="true" />
                                                                                    </button>
                                                                                </div>
                                                                                <div className="items-center">
                                                                                    <button
                                                                                        data-tooltip-id="delete-bt"
                                                                                        data-tooltip-content="Elimina"
                                                                                        data-tooltip-place="top"
                                                                                        onClick={() =>
                                                                                            handleDelete(
                                                                                                person.username,
                                                                                            )
                                                                                        }
                                                                                        className="text-red-400 hover:text-red-600 flex items-center"
                                                                                    >
                                                                                        <TrashIcon
                                                                                            className="h-5 w-5 mr-2"
                                                                                            aria-hidden="true"
                                                                                        />
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                            
                                                                        </td>
                                                                    </tr>
                                                                ),
                                                            )}
                                                        </tbody>
                                                    ) : null}
                                                </table>
                                            </div>
                                        </div>

                                        {/* Pagination section */}
                                        <div className="py-3 flex items-center justify-between">
                                            <div className="flex-1 flex justify-between sm:hidden">
                                                <button
                                                    onClick={prevPage}
                                                    className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                                                >
                                                    Precedente
                                                </button>
                                                <button
                                                    onClick={nextPage}
                                                    className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                                                >
                                                    Seguente
                                                </button>
                                            </div>
                                            <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                                                <div>
                                                    <p className="text-sm text-gray-700">
                                                        Pagina{" "}
                                                        <span className="font-medium">
                                                            {currentPage}
                                                        </span>{" "}
                                                        di{" "}
                                                        <span className="font-medium">
                                                            {totalPage}
                                                        </span>
                                                    </p>
                                                </div>
                                                <div>
                                                    <nav
                                                        className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
                                                        aria-label="Pagination"
                                                    >
                                                        <button
                                                            onClick={prevPage}
                                                            className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                                                        >
                                                            <span>
                                                                Precedente
                                                            </span>
                                                        </button>
                                                        {/* Generate buttons for each page */}
                                                        {Array.from(
                                                            {
                                                                length: totalPage,
                                                            },
                                                            (_, i) => (
                                                                <button
                                                                    key={i + 1}
                                                                    onClick={() =>
                                                                        paginate(
                                                                            i +
                                                                                1,
                                                                        )
                                                                    }
                                                                    className={`relative inline-flex items-center px-4 py-2 border text-sm font-medium ${i + 1 === currentPage ? "bg-gray-200" : "bg-white"} hover:bg-gray-50`}
                                                                >
                                                                    {i + 1}
                                                                </button>
                                                            ),
                                                        )}
                                                        <button
                                                            onClick={nextPage}
                                                            className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                                                        >
                                                            <span>
                                                                Seguente
                                                            </span>
                                                        </button>
                                                    </nav>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/**/}
                                </div>
                            </div>
                        </div>
                    </header>
                </div>
                {/* Modale di conferma Elimina Utente*/}
                <Dialog open={open} onClose={setOpen} className="relative z-10">
                    <DialogBackdrop
                        transition
                        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
                    />

                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <DialogPanel
                                transition
                                className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
                            >
                                <div className="sm:flex sm:items-start">
                                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                        <ExclamationTriangleIcon
                                            aria-hidden="true"
                                            className="h-6 w-6 text-red-600"
                                        />
                                    </div>
                                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                        <DialogTitle
                                            as="h3"
                                            className="text-base font-semibold leading-6 text-gray-900"
                                        >
                                            Cancella Utente
                                        </DialogTitle>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500">
                                                Sei sicuro di voler cancellare
                                                l'utente selezionato?
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                    <button
                                        type="button"
                                        onClick={() =>
                                            deleteUser(deleteUsername)
                                        }
                                        className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                                    >
                                        Elimina
                                    </button>
                                    <button
                                        type="button"
                                        data-autofocus
                                        onClick={() => setOpen(false)}
                                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                    >
                                        Annulla
                                    </button>
                                </div>
                            </DialogPanel>
                        </div>
                    </div>
                </Dialog>

                {/* Modale per Aggiunta e Modifica Nuovo Utente */}

                <Dialog
                    open={openAdd}
                    onClose={setOpenAdd}
                    className="relative z-10"
                >
                    <DialogBackdrop
                        transition
                        className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                    />
                    <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <DialogPanel
                                transition
                                className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:p-6 sm:max-w-7xl"
                                //ref={modal_ref}
                            >
                                <div className="sm:flex">
                                    <div className="mt-3 w-full text-center sm:ml-4 sm:mt-0 sm:text-left">
                                        <div className="flex flex-row items-center">
                                            <div className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full ${
                                                edit ? "bg-yellow-100" : "bg-green-100"
                                            } sm:mx-0 sm:h-10 sm:w-10`}>
                                                {
                                                    edit ?
                                                    <PencilSquareIcon
                                                        aria-hidden="true"
                                                        className="h-6 w-6 text-yellow-700"
                                                    /> :
                                                    <PlusCircleIcon
                                                        aria-hidden="true"
                                                        className="h-6 w-6 text-green-600"
                                                    />
                                                }
                                            </div>
                                            <div className="flex flex-col items-start ml-4">
                                                <DialogTitle
                                                    as="h3"
                                                    className="text-base font-semibold leading-6 text-gray-900"
                                                >
                                                    {
                                                        edit ? 
                                                        "Modifica Utente" :
                                                        "Crea Nuovo Utente"
                                                    }
                                                </DialogTitle>
                                                <div className="mt-2">
                                                    <p className="text-sm text-gray-500">
                                                        {
                                                            edit ?
                                                            "Inserisci i seguenti campi e clicca su Modifica" :
                                                            "Inserisci i seguenti campi e clicca su Crea"
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        {!isValid && 
                                            <AlertRed
                                                testo="La password deve contenere min. 8 caratteri tra cui almeno una lettera maiuscola, una lettera minuscola, un numero e un carattere speciale"
                                            />
                                        }

                                        <div className="w-full">
                                            {loadingAlertRed ? (
                                                <AlertRed
                                                    testo={textRedAlert}
                                                />
                                            ) : null}
                                        </div>

                                        <div className="mt-6 w-full isolate grid grid-cols-1 sm:grid-cols-2 gap-6">
                                            {/* Nome */}
                                            {
                                                !edit ? (
                                                    <div className="relative w-full px-3 pb-1.5 pt-2.5 ring-1 ring-inset ring-gray-300 focus-within:z-10 focus-within:ring-2 focus-within:ring-green-600">
                                                        <label
                                                            htmlFor="first-name"
                                                            className="block text-xs font-medium text-gray-900"
                                                        >
                                                        Nome
                                                        </label>
                                                        <input
                                                            onChange={handleaddNome}
                                                            value={addNome}
                                                            id="first-name"
                                                            name="first-name"
                                                            type="text"
                                                            placeholder="Mario"
                                                            className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                                        />
                                                    </div>
                                                ) : (
                                                    <div className="relative w-full px-3 pb-1.5 pt-2.5 ring-1 ring-inset ring-gray-300 focus-within:z-10 focus-within:ring-2 focus-within:ring-green-600">
                                                        <label
                                                            htmlFor="first-name-user"
                                                            className="block text-xs font-medium text-gray-900"
                                                        >
                                                        Nome
                                                        </label>
                                                        <input
                                                            onChange={handleChange}
                                                            value={userData.name}
                                                            id="first-name-user"
                                                            name="name"
                                                            type="text"
                                                            placeholder=""
                                                            className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                                        />
                                                    </div>
                                                )
                                            }
                                            

                                            {/* Cognome */}
                                            {
                                                !edit ? (
                                                    <div className="relative w-full px-3 pb-1.5 pt-2.5 ring-1 ring-inset ring-gray-300 focus-within:z-10 focus-within:ring-2 focus-within:ring-green-600">
                                                        <label
                                                            htmlFor="last-name"
                                                            className="block text-xs font-medium text-gray-900"
                                                        >
                                                        Cognome
                                                        </label>
                                                        <input
                                                            onChange={handleaddCognome}
                                                            value={addCognome}
                                                            id="last-name"
                                                            name="last-name"
                                                            type="text"
                                                            placeholder="Rossi"
                                                            className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                                        />
                                                    </div>
                                                ) : (
                                                    <div className="relative w-full px-3 pb-1.5 pt-2.5 ring-1 ring-inset ring-gray-300 focus-within:z-10 focus-within:ring-2 focus-within:ring-green-600">
                                                        <label
                                                            htmlFor="last-name-user"
                                                            className="block text-xs font-medium text-gray-900"
                                                        >
                                                        Cognome
                                                        </label>
                                                        <input
                                                            onChange={handleChange}
                                                            value={userData.surname}
                                                            id="last-name-user"
                                                            name="surname"
                                                            type="text"
                                                            placeholder="Rossi"
                                                            className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                                        />
                                                    </div>
                                                )
                                            }
                                            

                                            {/* Email */}

                                            {
                                                !edit ? (
                                                    <div className="relative w-full px-3 pb-1.5 pt-2.5 ring-1 ring-inset ring-gray-300 focus-within:z-10 focus-within:ring-2 focus-within:ring-green-600">
                                                    <label
                                                        htmlFor="email"
                                                        className="block text-xs font-medium text-gray-900"
                                                    >
                                                    Email
                                                    </label>
                                                    <input
                                                        onChange={handleaddEmail}
                                                        value={addEmail}
                                                        id="email"
                                                        name="email"
                                                        type="email"
                                                        placeholder="mario.rossi@example.com"
                                                        className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                                    />
                                                    </div>
                                                ) : (
                                                    <div className="relative w-full px-3 pb-1.5 pt-2.5 ring-1 ring-inset ring-gray-300 focus-within:z-10 focus-within:ring-2 focus-within:ring-green-600">
                                                    <label
                                                        htmlFor="email-user"
                                                        className="block text-xs font-medium text-gray-900"
                                                    >
                                                    Email
                                                    </label>
                                                    <input
                                                        onChange={handleChange}
                                                        value={userData.email}
                                                        id="email-user"
                                                        name="email"
                                                        type="email"
                                                        placeholder="mario.rossi@example.com"
                                                        className="block w-full border-0 p-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                                    />
                                                    </div> 
                                                )
                                            }
                                            

                                            {/* Password */}

                                            {/* Password con icona mostra/nascondi */}
                                            {
                                                !edit ? (
                                                    <div className={`relative w-full px-3 pb-1.5 pt-2.5 ring-1 ring-inset ring-gray-300 focus-within:z-10 focus-within:ring-2 ${
                                                    isValid ? "focus-within:ring-green-600" : "focus-within:ring-red-600"}`}>
                                                    <label
                                                        htmlFor="password"
                                                        className="block text-xs font-medium text-gray-900"
                                                    >
                                                    Password
                                                    </label>
                                                    <div className="relative">
                                                        <input
                                                            onChange={handleaddpassword}
                                                            onInput={handleControlPassword}
                                                            autoComplete="new-password"
                                                            value={addpassword}
                                                            id="password"
                                                            name="password"
                                                            type={passwordVisible ? "text" : "password"}
                                                            placeholder="••••••••"
                                                            className="block w-full border-0 p-0 pr-10 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                                        />
                                                        <button
                                                            type="button"
                                                            onClick={() => setPasswordVisible(!passwordVisible)}
                                                            className="absolute inset-y-0 right-0 flex items-center px-2 text-gray-600"
                                                        >
                                                            {passwordVisible ? (
                                                            <EyeSlashIcon className="h-5 w-5" aria-hidden="true" />
                                                            ) : (
                                                            <EyeIcon className="h-5 w-5" aria-hidden="true" />
                                                            )}
                                                        </button>
                                                    </div>
                                                    </div>
                                                ) : (
                                                    <div className={`relative w-full px-3 pb-1.5 pt-2.5 ring-1 ring-inset ring-gray-300 focus-within:z-10 focus-within:ring-2 ${
                                                        isValid ? "focus-within:ring-green-600" : "focus-within:ring-red-600"}`}>
                                                        <label
                                                            htmlFor="password-user"
                                                            className="block text-xs font-medium text-gray-900"
                                                        >
                                                        Nuova Password
                                                        </label>
                                                        <div className="relative">
                                                            <input
                                                                onInput={handleControlPassword}
                                                                onChange={handleChange}
                                                                autoComplete="new-password"
                                                                id="password-user"
                                                                name="password"
                                                                type={passwordVisible ? "text" : "password"}
                                                                placeholder="••••••••"
                                                                className="block w-full border-0 p-0 pr-10 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                                            />
                                                            <button
                                                                type="button"
                                                                onClick={() => setPasswordVisible(!passwordVisible)}
                                                                className="absolute inset-y-0 right-0 flex items-center px-2 text-gray-600"
                                                            >
                                                                {passwordVisible ? (
                                                                <EyeSlashIcon className="h-5 w-5" aria-hidden="true" />
                                                                ) : (
                                                                <EyeIcon className="h-5 w-5" aria-hidden="true" />
                                                                )}
                                                            </button>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            

                                            {/* Nome Utente */}
                                            {
                                                !edit ? (
                                                    <div className="relative w-full px-3 pb-1.5 pt-2.5 ring-1 ring-inset ring-gray-300 focus-within:z-10 focus-within:ring-2 focus-within:ring-green-600">
                                                        <label
                                                            htmlFor="username"
                                                            className="block text-xs font-medium text-gray-900"
                                                        >
                                                        Nome Utente
                                                        </label>
                                                        <input
                                                            onChange={handleaddUsername}
                                                            autoComplete="new-password" // Disabilita l'autosuggest del browser
                                                            value={addUsername}
                                                            id="username"
                                                            name="username"
                                                            type="text"
                                                            placeholder="mrossi"
                                                            className="block w-full border-0 py-1.5 pl-0 pr-10 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                                        />
                                                    </div>
                                                ) : (
                                                    <div className="relative w-full px-3 pb-1.5 pt-2.5 ring-1 ring-inset ring-gray-300 focus-within:z-10 focus-within:ring-2 focus-within:ring-green-600">
                                                        <label
                                                            htmlFor="username-user"
                                                            className="block text-xs font-medium text-gray-900"
                                                        >
                                                        Nome Utente
                                                        </label>
                                                        <input
                                                            autoComplete="new-password" // Disabilita l'autosuggest del browser
                                                            value={selectedUser.username}
                                                            id="username-user"
                                                            name="username"
                                                            type="text"
                                                            disabled
                                                            className="block w-full border-0 p-0 text-gray-500 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                                        />
                                                    </div>
                                                )
                                            }

                                            {/* Ruolo */}
                                            {
                                                !edit ? (
                                                    <div className="relative w-full px-3 pb-1.5 pt-2.5 ring-1 ring-inset ring-gray-300 focus-within:z-10 focus-within:ring-2 focus-within:ring-green-600">
                                                        <label
                                                            htmlFor="role"
                                                            className="block text-xs font-medium text-gray-900"
                                                        >
                                                        Ruolo
                                                        </label>
                                                        <select
                                                            onChange={handleaddRuolo}
                                                            value={addRuolo}
                                                            id="role"
                                                            name="role"
                                                            className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-0 ring-inset ring-gray-300 focus:ring-0 focus:ring-gray-300 sm:text-sm/6"
                                                        >
                                                        <option value="user">Utente</option>
                                                        <option value="admin">Admin</option>
                                                        </select>
                                                    </div>
                                                ) : (
                                                    <div className="relative w-full px-3 pb-1.5 pt-2.5 ring-1 ring-inset ring-gray-300 focus-within:z-10 focus-within:ring-2 focus-within:ring-green-600">
                                                        <label
                                                            htmlFor="role-user"
                                                            className="block text-xs font-medium text-gray-900"
                                                        >
                                                        Ruolo
                                                        </label>
                                                        <input
                                                            value={selectedUser.role}
                                                            id="role-user"
                                                            name="role"
                                                            disabled
                                                            className="block w-full border-0 p-0 text-gray-500 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                                                        >
                                                        </input>
                                                    </div>
                                                )
                                            }
                                            
                                        </div>
                                    </div>
                                </div>
                                {
                                    !edit ? (
                                        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                            <button
                                                onClick={() => handleAddUser()}
                                                type="button"
                                                className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto"
                                            >
                                                Crea
                                            </button>
                                            <button
                                                type="button"
                                                onClick={() => setOpenAdd(false)}
                                                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                            >
                                                Annulla
                                            </button>
                                        </div>
                                    ) : (
                                        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                            <button
                                                onClick={() => handleUpdateUser()}
                                                type="button"
                                                className="inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto"
                                            >
                                                Modifica
                                            </button>
                                            <button
                                                type="button"
                                                onClick={() => setOpenAdd(false)}
                                                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                            >
                                                Annulla
                                            </button>
                                        </div>
                                    )
                                }
                                
                            </DialogPanel>
                        </div>
                    </div>
                </Dialog>;

                <footer className="bg-white">
                    <div className="mx-auto px-6 sm:py-3 md:py-3 xl:py-5 items-center">
                        <div className="mt-4 md:order-1 md:mt-0">
                            <p className="text-center text-xs leading-5 text-gray-500">
                                {GetAnno()} © Centro del Sorriso, sviluppato
                                con il ❤️ da Tribus Informatica Srl
                            </p>
                        </div>
                    </div>
                </footer>
            </div>
        </>
    );
}

export default Utenti;
