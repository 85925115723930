import { Fragment } from "react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import ContainerImages from "./ContainerImages";

const ExpandableTable = ({ columns, data, setSrc, setOpenModalImage, loading }) => {


    const renderImages = (item) => {
        const url = item.url || "";
        const src_images = item.src_images || [];
    
        return (
            <tr>
                <td colSpan={columns.length} className="overflow-x-auto w-full px-10 py-4 text-sm text-gray-900 text-center whitespace-nowrap shadow-inner bg-gray-100">
                    <div className="flex space-x-4 overflow-x-auto rounded-md z-0">
                        {src_images && src_images.length > 0 && (
                            src_images.map((src_image, image_index) => (
                                <div 
                                    key={`image_${image_index}`} 
                                    className="w-48 h-32 rounded-lg overflow-hidden shadow-md mx-2 cursor-pointer"
                                    onClick={() => {
                                        setSrc(`${url}/${src_image.path}`);
                                        setOpenModalImage(true);
                                    }}
                                >
                                    <img
                                        alt={`Image preview ${image_index}`}
                                        src={`${url}/${src_image.path}`}
                                        className="w-full h-full object-cover"
                                    />
                                </div>
                            ))
                        )}
                    </div>
                </td>
            </tr>
        );
    };    

    return (
        <table  
            className="min-w-full divide-y divide-gray-300"
        >
            <thead className="rounded-t-xl">
                <tr className="w-full">
                    {columns.map((column) => (
                        <th
                            key={column.title}
                            scope="col"
                            className={`px-2 py-3.5 text-left text-sm font-semibold text-gray-900 text-center ${
                                column.title === "Azioni" ? "sticky right-0 bg-gray-100 z-10" : ""
                            }`}
                        >
                            {column.title}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody  className="rounded-b-xl divide-y divide-gray-200 bg-white">
                {loading ? (
                    data && data.length > 0 ? (
                        data.map((item, row_index) => (
                            <Fragment key={`row_${row_index}`}>
                                <tr>
                                    {columns.map((column, index) => (
                                        <td 
                                            key={`base_cell_${index}`}
                                            className={`px-10 py-4 text-sm text-gray-900 text-center whitespace-nowrap ${
                                                column.title === "Azioni" ? "sticky right-0 bg-gray-100 z-10" : ""
                                            }`}
                                        >
                                            {column.formatter(item, index)}
                                        </td>
                                    ))}
                                </tr>

                                {item.show && /*renderImages(item)*/ <ContainerImages
                                    columns={columns}
                                    item={item}
                                    setSrc={setSrc}
                                    setOpenModalImage={setOpenModalImage}
                                />}
                            </Fragment>
                        ))
                    ) : (
                        <tr>
                            <td 
                                colSpan={columns.length}
                                className="px-10 py-4 text-sm text-gray-900 text-center whitespace-nowrap"
                            >
                                <div className="w-full flex flex-col justify-center items-center col-span-4 py-4">
                                    <ExclamationTriangleIcon width={32} height={32} aria-hidden="true" className="text-indigo-600" />
                                    <p className="text-sm text-gray-500 text-center whitespace-nowrap">Nessun dato disponibile!</p>
                                </div>
                            </td>
                        </tr>
                    )
                ) : (
                    <tr>
                        <td colSpan={columns.length}> 
                            <div className="w-full flex justify-center items-center px-12 py-4">
                                <div className="w-8 h-8 border-4 border-blue-600 border-t-transparent rounded-full animate-spin" role="status">
                                    <span className="sr-only">Caricamento...</span>
                                </div>
                            </div>
                        </td>
                    </tr>
                )}
            </tbody>
        </table>

    )
}

export default ExpandableTable;