import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { logout } from "../features/auth/authSlice";
import { useNavigate } from "react-router-dom";
import {
    Disclosure,
    DisclosureButton,
    DisclosurePanel,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
} from "@headlessui/react";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
import Cookies from "js-cookie";
import CryptoJS from "crypto-js";

// Importo componenti
import StepWizard from "./componets/StepWizard";
import DragAndDrop from "./upload_wizard/DragAndDrop";
import Summary from "./upload_wizard/Summary";
import FinalRescue from "./upload_wizard/FinalRescue";

//Importo Autenticazione Token
import getAuthToken from "../features/auth/axiosSetup";

//Importo Menu
import { MenuDesktop } from "./componets/Menu_desktop";
import { MenuMobile } from "./componets/Menu_mobile";
import { MenuNavigazioneMobile } from "./componets/Menu_navigazioneMobile";
import { MenuNavigazioneDesktop } from "./componets/Menu_navigazioneDesktop";

function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
}

function Upload() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    //Dichiaro gli State
    const [patient_images, setPatientImages] = useState([]);
    const [nome, setNome] = useState("");
    const [cognome, setCognome] = useState("");
    const [ruolo, setRuolo] = useState("");
    const [email, setEmail] = useState("");
    const [active_step, setActiveStep] = useState(0);

    // Funzione per cancellare i Cookie criptati
    function removeEncryptedCookie(key) {
        // Rimuove il cookie specificato
        Cookies.remove(key);
    }
    // Funzione per riprendere i cookie salvati
    function getDecryptedCookie(key) {
        try {
            const encryptedValue = Cookies.get(key);

            if (!encryptedValue) {
                return null;
            }
            // Decriptazione
            const bytes = CryptoJS.AES.decrypt(
                encryptedValue,
                process.env.REACT_APP_COOKIE_SECRET,
            );

            if (bytes) {
                const decryptedValue = bytes.toString(CryptoJS.enc.Utf8);

                if (decryptedValue) {
                    return decryptedValue;
                } else {
                    return null;
                }
            } else {
                return null;
            }
        } catch (error) {
            return null;
        }
    }

    // Recupero le informazioni dai Cookie
    // Esegui il controllo una sola volta quando la pagina viene montata
    useEffect(() => {
        setNome(getDecryptedCookie(process.env.REACT_APP_COOKIE_FNAME));
        setCognome(getDecryptedCookie(process.env.REACT_APP_COOKIE_LNAME));
        setRuolo(getDecryptedCookie(process.env.REACT_APP_COOKIE_ROLE));
        setEmail(getDecryptedCookie(process.env.REACT_APP_COOKIE_EMAIL));
    }, []);

    const handleLogout = () => {
        //Cancello i Cookiee
        removeEncryptedCookie(process.env.REACT_APP_COOKIE_FNAME);
        removeEncryptedCookie(process.env.REACT_APP_COOKIE_LNAME);
        removeEncryptedCookie(process.env.REACT_APP_COOKIE_ROLE);
        removeEncryptedCookie(process.env.REACT_APP_COOKIE_EMAIL);
        removeEncryptedCookie(process.env.REACT_APP_COOKIE_USERNAME);

        //Eseguo Logout
        dispatch(logout());
        navigate("/login");
    };

    const handleProfilo = () => {
        navigate("/profilo");
    };

    //Funzione per Generare l'immagine
    function generateInitialsImage(name, surname) {
        const initials = (name[0] || "") + (surname[0] || "");
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");

        // Imposta le dimensioni del canvas
        const size = 80; // Dimensione dell'immagine
        canvas.width = size;
        canvas.height = size;

        // Imposta il colore di sfondo
        context.fillStyle = "#5930B6"; // Colore di sfondo (puoi personalizzarlo)
        context.fillRect(0, 0, size, size);

        // Imposta le proprietà del testo
        context.fillStyle = "#fff"; // Colore del testo
        context.font = "36px Roboto"; // Font e dimensione del testo

        context.textAlign = "center";
        context.textBaseline = "middle";

        // Disegna le iniziali al centro del canvas
        context.fillText(initials, size / 2, size / 2);

        // Ritorna l'URL dell'immagine generata
        return canvas.toDataURL("image/png");
    }

    // Genera l'immagine con le iniziali dell'utente
    const initialsImageUrl = generateInitialsImage(nome, cognome);

    const GetAnno = () => {
        return new Date().getFullYear();
    };

    useEffect(()=>{
        if(active_step === 0){
            setPatientImages([])
        }
    },[active_step])

    return (
        <>
            <div className="flex flex-col min-h-screen bg-gray-100">
                <Disclosure as="nav" className="bg-white shadow-sm">
                    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                        <div className="flex h-16 justify-between">
                            <div className="flex">
                                <div className="flex flex-shrink-0 items-center">
                                    <img
                                        alt="Your Company"
                                        src="https://fileserver.centrodelsorriso.tribusinformatica.it/logo_top.png"
                                        className="block h-8 w-auto lg:hidden"
                                    />
                                    <img
                                        alt="Your Company"
                                        src="https://fileserver.centrodelsorriso.tribusinformatica.it/logo_top.png"
                                        className="hidden h-8 w-auto lg:block"
                                    />
                                </div>
                                <MenuNavigazioneDesktop
                                    ruolo={ruolo}
                                    navigate={navigate}
                                />
                            </div>

                            <div className="hidden sm:ml-6 sm:flex sm:items-center">
                                {/* Profile dropdown */}
                                <MenuDesktop
                                    nome={nome}
                                    cognome={cognome}
                                    ruolo={ruolo}
                                    email={email}
                                    initialsImageUrl={initialsImageUrl}
                                    handleLogout={handleLogout}
                                    handleProfilo={handleProfilo}
                                />
                            </div>

                            {/* Mobile Section */}
                            <div className="-mr-2 flex items-center sm:hidden">
                                {/* Mobile menu button */}
                                <DisclosureButton className="group relative inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                    <span className="absolute -inset-0.5" />
                                    <span className="sr-only">
                                        Open main menu
                                    </span>
                                    <Bars3Icon
                                        aria-hidden="true"
                                        className="block h-6 w-6 group-data-[open]:hidden"
                                    />
                                    <XMarkIcon
                                        aria-hidden="true"
                                        className="hidden h-6 w-6 group-data-[open]:block"
                                    />
                                </DisclosureButton>
                            </div>
                        </div>
                    </div>

                    {/* Mobile  */}
                    <DisclosurePanel className="sm:hidden">
                        <MenuNavigazioneMobile
                            ruolo={ruolo}
                            navigate={navigate}
                        />
                        <MenuMobile
                            nome={nome}
                            cognome={cognome}
                            ruolo={ruolo}
                            email={email}
                            initialsImageUrl={initialsImageUrl}
                            handleLogout={handleLogout}
                            handleProfilo={handleProfilo}
                        />
                    </DisclosurePanel>
                </Disclosure>

                <div className="flex-grow py-10">
                    <header>
                        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                            <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">
                                Upload
                            </h1>
                        </div>
                    </header>
                    <main>
                        <div className="mx-auto max-w-7xl px-4 py-8 sm:px-6 lg:px-8">
                            <StepWizard
                                steps={[
                                    "Caricamento immagini",
                                    "Riepilogo immagini",
                                    "Valutazione finale"
                                ]}
                                active_step={active_step}
                            />
                        </div>
                        {
                            active_step === 0 ?
                            <DragAndDrop
                                patient_images={patient_images}
                                setPatientImages={setPatientImages}
                                getAuthToken={getAuthToken}
                                setActiveStep={setActiveStep}
                            /> :
                            active_step === 1 ?
                            <Summary
                                patient_images={patient_images}
                                setPatientImages={setPatientImages}
                                getAuthToken={getAuthToken}
                                setActiveStep={setActiveStep}
                            /> : active_step === 2 ?
                            <FinalRescue 
                                patient_images={patient_images}
                                setPatientImages={setPatientImages}
                                getAuthToken={getAuthToken}
                                setActiveStep={setActiveStep}
                            /> :
                            null
                        }
                    </main>
                </div>

                <footer className="bg-white">
                    <div className="mx-auto px-6 sm:py-3 md:py-3 xl:py-5 items-center">
                        <div className="mt-4 md:order-1 md:mt-0">
                            <p className="text-center text-xs leading-5 text-gray-500">
                                {GetAnno()} © Centro del Sorriso, sviluppato
                                con il ❤️ da Tribus Informatica Srl
                            </p>
                        </div>
                    </div>
                </footer>
            </div>
        </>
    );
}

export default Upload;
