import { UserIcon, ArrowLeftStartOnRectangleIcon } from "@heroicons/react/20/solid";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom"; // Importa questi hook da React Router

function MenuDesktop({
    nome,
    cognome,
    ruolo,
    email,
    initialsImageUrl,
    handleLogout,
    handleProfilo,
}) {
    const location = useLocation();
    const navigate = useNavigate(); 

    // Funzione per determinare se la pagina è attiva
    const isSelected = (path) => location.pathname === path;

    return (
        <Menu as="div" className="relative ml-3">
            <div>
                <MenuButton className="relative flex rounded-full bg-white text-sm focus:outline-none">
                    <div className="flex items-center px-4">
                        <div className="flex-shrink-0">
                            <img
                                alt=""
                                src={initialsImageUrl}
                                className="h-8 w-8 rounded-full"
                            />
                        </div>
                        <div className="ml-3">
                            <div className="text-sm text-gray-800">
                                {nome} {cognome}
                            </div>
                            <div className="text-xs text-gray-500">
                                <strong>Ruolo: </strong>
                                {ruolo}
                            </div>
                        </div>
                    </div>
                </MenuButton>
            </div>

            <MenuItems
                transition
                className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in flex flex-col justify-center items-center"
            >
                <MenuItem
                    key="Profilo"
                    className={`cursor-pointer w-full ${
                        isSelected('/profilo') ? 'text-violet-600' : 'hover:bg-gray-100'
                    }`}
                >
                    <button
                        onClick={() => {
                            handleProfilo();
                        }}
                        className="block w-full px-4 py-2 text-sm text-gray-700 hover:text-violet-600 focus:text-violet-600 active:text-violet-600"
                    >
                        <span className="flex justify-center items-center">
                            <UserIcon
                                className={`h-3 w-3 mr-2 ${
                                    isSelected('/profilo') ? 'text-violet-600' : ''
                                }`}
                                aria-hidden="true"
                            />
                            Profilo
                        </span>
                    </button>
                </MenuItem>
                <MenuItem
                    key="Esci"
                    className={`cursor-pointer w-full ${
                        isSelected('/esci') ? 'text-violet-600' : 'hover:bg-gray-100'
                    }`}
                >
                    <button
                        onClick={() => {
                            handleLogout();
                        }}
                        className="block w-full px-4 py-2 text-sm text-gray-700 hover:text-violet-600"
                    >
                        <span className="flex justify-center items-center">
                            <ArrowLeftStartOnRectangleIcon
                                className="h-3 w-3 mr-2"
                                aria-hidden="true"
                            />
                            Esci
                        </span>
                    </button>
                </MenuItem>
            </MenuItems>
        </Menu>
    );
}

export { MenuDesktop };
