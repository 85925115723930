import React, { useCallback, useEffect, useState } from "react";
import {
    Dialog,
    DialogBackdrop,
    DialogPanel,
} from "@headlessui/react";

const ModalCode = ({ code, open, setOpen }) => {
    const [isFullscreen, setIsFullscreen] = useState(false);

    // Funzione che gestisce l'uscita dalla modalità schermo intero
    const exitHandler = () => {
        if (!document.fullscreenElement && 
            !document.webkitFullscreenElement && 
            !document.mozFullScreen && 
            !document.msFullscreenElement) {
            setIsFullscreen(false); // Modalità fullscreen è terminata
            setOpen(false); // Chiudi il modale
        }
    };

    // Aggiungi e rimuovi il listener per il cambio della modalità schermo intero
    useEffect(() => {
        // Aggiungi gli eventi per il cambio della modalità schermo intero
        document.addEventListener('webkitfullscreenchange', exitHandler, false);
        document.addEventListener('mozfullscreenchange', exitHandler, false);
        document.addEventListener('fullscreenchange', exitHandler, false);
        document.addEventListener('MSFullscreenChange', exitHandler, false);

        // Cleanup: rimuovi i listener quando il componente viene smontato
        return () => {
            document.removeEventListener('webkitfullscreenchange', exitHandler);
            document.removeEventListener('mozfullscreenchange', exitHandler);
            document.removeEventListener('fullscreenchange', exitHandler);
            document.removeEventListener('MSFullscreenChange', exitHandler);
        };
    }, [open, isFullscreen]);

    // Funzione per entrare in modalità fullscreen
    const enterFullscreen = () => {
        const docElement = document.documentElement;

        if (docElement.requestFullscreen) {
            docElement.requestFullscreen();
        } else if (docElement.mozRequestFullScreen) { // Firefox
            docElement.mozRequestFullScreen();
        } else if (docElement.webkitRequestFullscreen) { // Chrome, Safari, Opera
            docElement.webkitRequestFullscreen();
        } else if (docElement.msRequestFullscreen) { // IE/Edge
            docElement.msRequestFullscreen();
        }
    };

    // Funzione per gestire il clic per entrare in modalità fullscreen
    const handleFullscreenClick = () => {
        if (open) {
            enterFullscreen();
            setIsFullscreen(true); // Modalità fullscreen è attiva
        }
    };

    // Quando il modale si apre, entra in modalità fullscreen
    useEffect(() => {
        if (open) {
            handleFullscreenClick();
        }
    }, [open]);

    return (
        <Dialog open={open} onClose={setOpen} className="relative z-10">
            <DialogBackdrop
                transition
                className="fixed inset-0 bg-black bg-opacity-75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
            />

            {/* Fullscreen wrapper */}
            <div className="fixed inset-0 z-10 w-full h-full overflow-y-auto flex items-center justify-center">
                <DialogPanel
                    transition
                    className="relative transform overflow-hidden bg-white text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in w-full h-full sm:w-full sm:h-full"
                >
                    {/* Close Button */}
                    <button
                        onClick={() => {
                            // Disattiva la modalità fullscreen
                            if (document.exitFullscreen) {
                                document.exitFullscreen();
                            } else if (document.webkitExitFullscreen) { // Safari
                                document.webkitExitFullscreen();
                            } else if (document.mozCancelFullScreen) { // Firefox
                                document.mozCancelFullScreen();
                            } else if (document.msExitFullscreen) { // IE/Edge
                                document.msExitFullscreen();
                            }
                            // Chiudi il modale
                            setIsFullscreen(false);
                            setOpen(false);
                        }}
                        className="absolute top-4 right-4 text-white text-2xl font-semibold hover:text-red-500"
                    >
                        &times; {/* Questo è il simbolo "X" */}
                    </button>

                    {/* Contenuto del fullscreen */}
                    <div className="w-full h-full flex justify-center items-center">
                        <h1 className="text-center text-black font-semibold text-8xl">{code}</h1>
                    </div>
                </DialogPanel>
            </div>
        </Dialog>
    );
};

export default ModalCode;
